import { SkillLvl } from "react-skillbars";

const skillsData: Array<SkillLvl> = [
    { type: 'MOBILE', level: 100 },
    { type: 'WEB', level: 95 },
    { type: 'BACKEND', level: 90 },
    { type: 'VIDEO', level: 100 },
    { type: 'GRAPHICS DESIGN', level: 100 },
    { type: '3D', level: 85 },
    { type: 'CLOUD', level: 100 },
    { type: 'PEOPLE SKILLS', level: 100 },
    { type: 'COFFEE', level: 100 }
];

export default skillsData


