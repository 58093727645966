import './App.scss';

import { Card, Col, Container, Jumbotron, Row } from 'react-bootstrap';
import React, { CSSProperties, Component, useEffect, useState } from "react";

import { Contact } from "./contact";
import { ContactData } from "./contact.data";
import ReactGA from 'react-ga';
import Skills from "./skills";
import { TeamMember } from "./team.member";
import { TeamMemberData } from "./team.member.data";
import facebook from "../assets/icons/facebook_icon.svg";
import instagram from "../assets/icons/instagram_icon.svg";
import linkedin from "../assets/icons/linkedin_icon.svg"
import twitter from "../assets/icons/twitter_icon.svg"

interface AppProps {
    stefan: TeamMember,
    florentin: TeamMember,
    andrei: TeamMember,
    hortensiu: TeamMember,
    constantin: TeamMember,
    contactUs: Contact,
}

interface AppState {
}

export class App extends Component<AppProps, AppState> {
    static defaultProps = {
        stefan: TeamMemberData.info.stefan,
        florentin: TeamMemberData.info.florentin,
        andrei: TeamMemberData.info.andrei,
        hortensiu: TeamMemberData.info.hortensiu,
        constantin: TeamMemberData.info.constantin,
        contactUs: ContactData.info
    };

    static changeJumbotronImage() {
        return '../assets/images/large_stefan.jpg';
    }

    static getCurrentYear() {
        let date = new Date();
        return date.getFullYear();
    }

    static getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState(
            App.getWindowDimensions()
        );
        useEffect(() => {
            function handleResize() {
                setWindowDimensions(App.getWindowDimensions());
            }

            window.addEventListener("resize", handleResize);
            return () => window.removeEventListener("resize", handleResize);
        }, []);
        return windowDimensions;
    }

    static styleSocialIcon(image: string, floatValue: any): CSSProperties {
        return {
            backgroundImage: `url(${image})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            width: '135px',
            height: '90px',
            float: floatValue,
        };
    }

    componentDidMount(): void {
        ReactGA.initialize('UA-53453141-3');
        ReactGA.pageview(window.location.pathname + window.location.search);

        let carouselImages = [this.props.florentin.bigImage, this.props.stefan.bigImage, this.props.andrei.bigImage];
        let start = 0;
        setInterval(
            () => {
                start++;
                let jumbotron: HTMLElement | null;
                jumbotron = document.getElementById('jumbotron');
                if (jumbotron) jumbotron.style.backgroundImage = `url(${carouselImages[start % carouselImages.length]})`;
                if (start === carouselImages.length) start = 0;
            },
            10000
        );
    }

    render() {
        return (
            <div className="App">

                <Jumbotron id="jumbotron" style={{ height: App.getWindowDimensions().height }}>
                    <Row className="justify-content-md-center">
                        <Col xs lg="4" />
                        <Col xs lg="4" />
                        <Col xs lg="4"><h1 className="welcome-text">Welcome</h1></Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col xs lg="4" />
                        <Col xs lg="4" />
                        <Col xs lg="4"><h2 className="our-website-text">TO OUR WEBSITE</h2></Col>
                    </Row>
                </Jumbotron>

                <Container>
                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p className="we-are-text">We are</p>
                            <div className="we-are-logo"
                                style={{ backgroundImage: `url(${this.props.contactUs.logo})` }} />
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p><span>A&nbsp;</span>bunch of freelancers gathered to work even on cloudy weather, or
                                stormy winds.</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p><span>B</span>ut we prefer shiny beaches, cold cocktails and fast laptops.</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p>Hopefully you prefer as well</p>
                        </Col>
                        <Col xs lg="2" />
                        <Col xs lg="2" />
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p><span>C</span>an you imagine the quality of work if our team works together ?</p>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p>We can!</p>
                        </Col>
                        <Col xs lg="2" />
                        <Col xs lg="2" />
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p style={{ fontFamily: 'IntelligentDesign-ZonaPro-Black' }}>
                                <span style={{ fontFamily: 'IntelligentDesign-ZonaPro-Thin' }}>SOME OF</span> OUR
                                PROFESSIONAL SKILLS AND AREAS
                            </p>
                        </Col>
                    </Row>

                    <Row className="skills-bar-container">
                        <Skills />
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto">
                            <p>SOME OF OUR <span
                                style={{ fontFamily: 'IntelligentDesign-ZonaPro-Black' }}> CLIENTS.</span></p>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col md="auto"><p>ORACLE / SOCIETE GENERALE / EGIT / OSF GS / SCH DIGITAL &amp; more.</p></Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col xs lg="2">
                            <p style={{ fontFamily: 'IntelligentDesign-ZonaPro-Black' }}>COLLAB.</p>
                        </Col>
                        <Col md="auto" />
                        <Col xs lg="2" />
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col xs>
                            <Card>
                                <Card.Img variant="top" src={this.props.stefan.smallImage} />
                                <Card.Body>
                                    <Card.Title>{this.props.stefan.firstName} {this.props.stefan.lastName}</Card.Title>
                                    <Card.Text>{this.props.stefan.role}</Card.Text>
                                    <Card.Link href={this.props.stefan.linkedin}>
                                        <div style={App.styleSocialIcon(linkedin, 'left')} />
                                    </Card.Link>
                                    <Card.Link href={this.props.stefan.twitter}>
                                        <div style={App.styleSocialIcon(twitter, 'right')} />
                                    </Card.Link>
                                    <div className="clear" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={{ order: 1 }}>
                            <Card>
                                <Card.Img variant="top" src={this.props.florentin.smallImage} />
                                <Card.Body>
                                    <Card.Title>{this.props.florentin.firstName} {this.props.florentin.lastName}</Card.Title>
                                    <Card.Text>{this.props.florentin.role}</Card.Text>
                                    <Card.Link href={this.props.florentin.linkedin}>
                                        <div style={App.styleSocialIcon(linkedin, 'left')} />
                                    </Card.Link>
                                    <Card.Link href={this.props.florentin.linkedin}>
                                        <div style={App.styleSocialIcon(linkedin, 'right')} />
                                    </Card.Link>
                                    <div className="clear" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs={{ order: 12 }}>
                            <Card>
                                <Card.Img variant="top" src={this.props.andrei.smallImage} />
                                <Card.Body>
                                    <Card.Title>{this.props.andrei.firstName} {this.props.andrei.lastName}</Card.Title>
                                    <Card.Text>{this.props.andrei.role}</Card.Text>
                                    <Card.Link href={this.props.andrei.linkedin}>
                                        <div style={App.styleSocialIcon(linkedin, 'left')} />
                                    </Card.Link>
                                    <Card.Link href={this.props.andrei.instagram}>
                                        <div style={App.styleSocialIcon(instagram, 'right')} />
                                    </Card.Link>
                                    <div className="clear" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="justify-content-md-center">
                        <Col xs lg="4">
                            <Card>
                                <Card.Img variant="top" src={this.props.hortensiu.smallImage} />
                                <Card.Body>
                                    <Card.Title>{this.props.hortensiu.firstName} {this.props.hortensiu.lastName}</Card.Title>
                                    <Card.Text>{this.props.hortensiu.role}</Card.Text>
                                    <Card.Link href={this.props.hortensiu.instagram}>
                                        <div style={App.styleSocialIcon(instagram, 'left')} />
                                    </Card.Link>
                                    <Card.Link href={this.props.hortensiu.facebook}>
                                        <div style={App.styleSocialIcon(facebook, 'right')} />
                                    </Card.Link>
                                    <div className="clear" />
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md="auto" />
                        <Col xs lg="4">
                            <Card>
                                <Card.Img variant="top" src={this.props.constantin.smallImage} />
                                <Card.Body>
                                    <Card.Title>{this.props.constantin.firstName} {this.props.constantin.lastName}</Card.Title>
                                    <Card.Text>{this.props.constantin.role}</Card.Text>
                                    <Card.Link href={this.props.constantin.facebook}>
                                        <div style={App.styleSocialIcon(facebook, 'left')} />
                                    </Card.Link>
                                    <Card.Link href={this.props.constantin.instagram}>
                                        <div style={App.styleSocialIcon(instagram, 'right')} />
                                    </Card.Link>
                                    <div className="clear" />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                <footer>
                    <Container>
                        <Row className="justify-content-md-center">
                            <p className="contact-us">CONTACT US.</p>
                        </Row>
                        <Row className="justify-content-md-center">
                            <p className="footer-initials">T&nbsp;</p>
                            <p>{this.props.contactUs.mobile}</p>
                        </Row>
                        <Row className="justify-content-md-center">
                            <p className="footer-initials">E&nbsp;</p>
                            <p>{this.props.contactUs.email}</p>
                        </Row>
                        <Row className="justify-content-md-center">
                            <p className="footer-initials">A&nbsp;</p>
                            <p>{this.props.contactUs.address}</p>
                        </Row>
                        <Row className="justify-content-md-center">
                            <p className="footer-initials">W&nbsp;</p>
                            <p>{this.props.contactUs.website}</p>
                        </Row>
                        <Row className="justify-content-md-center">
                            <p>&copy;&nbsp;<img height="64" width="64" src={this.props.contactUs.logo}
                                alt="SCH Digital Ltd." />
                                <span className="footer-year">&nbsp;{App.getCurrentYear()}</span>
                            </p>
                        </Row>

                        <Row style={{ display: 'none' }} className="justify-content-md-center">
                            <Col xs lg="2" />
                            <Col md="auto">
                                <h2 style={{ fontFamily: 'AbsoluteNeonScript' }}>SCH Digital Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-Black' }}>SCH Digital Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-BlackItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-Bold' }}>SCH Digital Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-BoldItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-ExtraBold' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-ExtraBoldItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-Hairline' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-HairlineItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-Light' }}>SCH Digital Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-LightItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-Regular' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-RegularItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-SemiBold' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-SemiBoldItalic' }}>SCH Digital
                                    Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-Thin' }}>SCH Digital Consulting</h2>
                                <h2 style={{ fontFamily: 'IntelligentDesign-ZonaPro-ThinItalic' }}>SCH Digital
                                    Consulting</h2>
                            </Col>
                            <Col xs lg="2" />
                        </Row>
                    </Container>
                </footer>

            </div>
        );
    }
}

export default App;
