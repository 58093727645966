import andreiBigImage from "../assets/images/large_andrei.jpg"
import andreiSmallImage from "../assets/images/icon_andrei.png";
import constantinSmallImage from "../assets/images/icon_constantin.png";
import florentinBigImage from "../assets/images/large_florentin.jpg"
import florentinSmallImage from "../assets/images/icon_florentin.png";
import hortensiuSmallImage from "../assets/images/icon_hortensiu.png";
import stefanBigImage from "../assets/images/large_stefan.jpg";
import stefanSmallImage from "../assets/images/icon_stefan.png";

export class TeamMemberData {
    public static info = {
        stefan: {
            firstName: "Stefan",
            lastName: "Hotoleanu",
            role: "Web Developer.",
            smallImage: stefanSmallImage,
            bigImage: stefanBigImage,
            facebook: '',
            linkedin: 'https://www.linkedin.com/in/stefanciprianhotoleanu/',
            instagram: '',
            pinterest: '',
            twitter: 'https://twitter.com/stefciprian'
        },
        florentin: {
            firstName: "Florentin",
            lastName: "Dana",
            role: "Mobile Developer.",
            smallImage: florentinSmallImage,
            bigImage: florentinBigImage,
            facebook: '',
            linkedin: 'https://www.linkedin.com/in/florentin-dana-9775a33b/?originalSubdomain=ro',
            instagram: '',
            pinterest: '',
            twitter: ''
        },
        andrei: {
            firstName: "Andrei",
            lastName: "Hotoleanu",
            role: "Social media / PR.",
            smallImage: andreiSmallImage,
            bigImage: andreiBigImage,
            facebook: 'https://www.facebook.com/differentmotorcycle/',
            linkedin: 'https://www.linkedin.com/in/andrei-eugen-hotoleanu-8aa393aa/',
            instagram: 'https://www.instagram.com/differentmotorcycle/',
            pinterest: '',
            twitter: ''
        },
        hortensiu: {
            firstName: "Hortensiu",
            lastName: "Atanasie",
            role: "Videographer.",
            smallImage: hortensiuSmallImage,
            bigImage: "",
            facebook: "https://www.facebook.com/hydrangeafilm/",
            linkedin: '',
            instagram: "https://www.instagram.com/hydrangeafilm/",
            pinterest: '',
            twitter: ''
        },
        constantin: {
            firstName: "Constantin",
            lastName: "HYP",
            role: "Graphic design. 3D generalist.",
            smallImage: constantinSmallImage,
            bigImage: "",
            facebook: "https://www.facebook.com/roundbirds",
            linkedin: '',
            instagram: "https://www.instagram.com/constantinhyp/",
            pinterest: '',
            twitter: ''
        }
    };
}