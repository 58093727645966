import logo from "../assets/icons/logo_sch_digital.svg";

export class ContactData {
    public static info = {
        mobile: '(+44) 203 318 5133 / (+40) 756 800 169',
        email: 'contact@schdigital.co.uk',
        address: 'London, UK / Bucharest, RO',
        website: 'schdigital.co.uk',
        logo
    }
}